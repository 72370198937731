<template>
    <h5 class="orange-text">Esperando Hash...</h5>
    <Loader />
    <h6 class="orange-text">Puede Demorar Hasta 1 Minuto</h6>
</template>

<script>
import Loader from '@/components/UI/Loader'
export default {
    components: { Loader }
}
</script>