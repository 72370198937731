<template>
    <div class = "container grey darken-3 successRow">
        <div class="row marginTop ">
            <Success title="Transacción Propagada" @goBack="goBack">
                <WaitingForHash v-if="hash==null"/>
                <HashDetails v-else :hash="hash" />
            </Success>
        </div>
    </div>
</template>

<script>
import Success from '@/components/UI/Success'
import WaitingForHash from '@/components/Hash/WaitingForHash'
import HashDetails from '@/components/Hash/HashDetails'
import { useRouter } from 'vue-router'
import { ref } from '@vue/reactivity'
import { socket } from '@/composables/socket'

export default {
    components: { WaitingForHash, HashDetails, Success },
    props: [ 'escrow' ],
    setup(props){
        
        //TEST ROUTE:
        //http://localhost:8080/wallet/escrow-success/61056e5044b26f0eea39bede
        
        const router = useRouter();
        const hash = ref(null);
        const goBack = () => router.push({name: "Escrow", params: {id: props.escrow}});
        
        socket.on('new escrow stage', data => {
            const canIchange = props.escrow===data._id;
            if(canIchange===true){
                hash.value = data.hashDelivery;
            }
        })

        return { hash, goBack }
    }
}
</script>

<style>

</style>