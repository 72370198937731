<template>
    <h5 class="white-text center-align">Hash: </h5>
    <h6 class="green-text center-align">{{ cutHash }}</h6>
</template>

<script>
import { cutKey } from '@/composables/helpers'
import { computed } from '@vue/runtime-core'
export default {
    props: ['hash'],
    setup(props){
        const cutHash = computed(() => cutKey(props.hash, 7));
        return { cutHash }
    }
}
</script>